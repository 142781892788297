import { ENVIRONMENT_CONFIG } from "../config/env"

let CONTRACT_SERVER_ENDPOINT,
    ACTION_SERVER_ENDPOINT,
    PAYLOAD_SERVER_ENDPOINT,
    IDENTITY_SERVER_ENDPOINT,
    ACCOUNT_SERVER_ENDPOINT,
    RECORD_SERVER_ENDPOINT,
    BALANCE_SERVER_ENDPOINT,
    ACTION_LOG_SERVER_ENDPOINT,
    PENDINGACTION_LOG_SERVER_ENDPOINT

switch(ENVIRONMENT_CONFIG) {
    case 'Dev':
        CONTRACT_SERVER_ENDPOINT = "https://ibvbj62x21.execute-api.us-west-1.amazonaws.com/Dev/book/contract" 
        ACTION_SERVER_ENDPOINT = "https://ck876yo5f7.execute-api.us-west-1.amazonaws.com/Dev/validation/action"
        PAYLOAD_SERVER_ENDPOINT = "https://z5nnd0m9yi.execute-api.us-west-1.amazonaws.com/Dev/user/flows/userPayload" 
        IDENTITY_SERVER_ENDPOINT = "https://z5nnd0m9yi.execute-api.us-west-1.amazonaws.com/Dev/user/flows/identity"
        ACCOUNT_SERVER_ENDPOINT = "https://6a8tc85iz5.execute-api.us-west-1.amazonaws.com/Dev/user/account" 
        RECORD_SERVER_ENDPOINT = "https://z5nnd0m9yi.execute-api.us-west-1.amazonaws.com/Dev/user/flows/createRecord" 
        BALANCE_SERVER_ENDPOINT = "https://c0y55wnaae.execute-api.us-west-1.amazonaws.com/Dev/user/balance" 
        ACTION_LOG_SERVER_ENDPOINT = "https://5v6fmw9c9d.execute-api.us-west-1.amazonaws.com/Dev/user/actionLog"
        PENDINGACTION_LOG_SERVER_ENDPOINT = "https://y6rslprvk1.execute-api.us-west-1.amazonaws.com/Dev/user/pendingActionLog"
        break;
    case 'Prod':
        CONTRACT_SERVER_ENDPOINT = "https://fife9wz71i.execute-api.us-west-1.amazonaws.com/Prod/book/contract"
        ACTION_SERVER_ENDPOINT = "https://ravvtbf4mi.execute-api.us-west-1.amazonaws.com/Prod/validation/action"
        PAYLOAD_SERVER_ENDPOINT = "https://g85x8da0nk.execute-api.us-west-1.amazonaws.com/Prod/user/flows/userPayload"
        IDENTITY_SERVER_ENDPOINT = "https://g85x8da0nk.execute-api.us-west-1.amazonaws.com/Prod/user/flows/identity"
        ACCOUNT_SERVER_ENDPOINT = "https://j3wyjdhrgk.execute-api.us-west-1.amazonaws.com/Prod/user/account"
        RECORD_SERVER_ENDPOINT = "https://g85x8da0nk.execute-api.us-west-1.amazonaws.com/Prod/user/flows/createRecord"
        BALANCE_SERVER_ENDPOINT = "https://6s88j8c29i.execute-api.us-west-1.amazonaws.com/Prod/user/balance"
        ACTION_LOG_SERVER_ENDPOINT = "https://q62e6p9kl2.execute-api.us-west-1.amazonaws.com/Prod/user/actionLog"
        PENDINGACTION_LOG_SERVER_ENDPOINT = "https://2fcvjz956f.execute-api.us-west-1.amazonaws.com/Prod/user/pendingActionLog"
        break;
}

export {
    CONTRACT_SERVER_ENDPOINT,
    ACTION_SERVER_ENDPOINT,
    PAYLOAD_SERVER_ENDPOINT,
    IDENTITY_SERVER_ENDPOINT,
    ACCOUNT_SERVER_ENDPOINT,
    RECORD_SERVER_ENDPOINT,
    BALANCE_SERVER_ENDPOINT,
    ACTION_LOG_SERVER_ENDPOINT,
    PENDINGACTION_LOG_SERVER_ENDPOINT
    }