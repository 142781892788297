import React from "react";
import axios from "axios";
import { ACCOUNT_SERVER_ENDPOINT } from "../../store/api";

const MAX_RETRIES = 1;

export async function getAccounts(jwt, account_type) {
  //console.log('try get accounts', account_id)
  return new Promise ((resolve, reject) =>
    {
      for (let retry = 0; retry <= MAX_RETRIES; retry++) {
        try {
          axios.get(ACCOUNT_SERVER_ENDPOINT, {
            params: {
                account_type: account_type
            },
            headers: {
              'Authorization': jwt
            }
          })
          .then(response => {
            resolve(response.data)
            //console.log('got accounts', response.data)
          })
        } catch (error) {
          if (retry === MAX_RETRIES) {
            // If it's the last retry, return the error
            reject(error.response || { status: 404, data: {} });
          }
          // If it's not the last retry, log the error and retry
          console.error(`Error in request, retrying... (Retry ${retry + 1})`, error);
        }
      }
    }
  ) 
}

export async function postAccount(jwt, account, isActive, isPrimary) {
    return new Promise((resolve, reject) => {
        //console.log('account posted trying',account)
      try {
        axios
          .post(
            ACCOUNT_SERVER_ENDPOINT,
            {
                account_id: account.account_id,
                payload_id: account.payload_id,
                tags: account.tags,
                isActive: isActive,
                isPrimary: isPrimary
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwt,
              },
              mode: "cors",
              credentials: "include",
            }
          )
          .then((response) => {
            //console.log('RES',response);
            resolve(response);
          })
      } catch (error) {
        reject(error);
      }
    });
  }
