import "./polyfills";
import React from "react";
import { createRoot } from 'react-dom/client';

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./flows/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";

import { 
  AuthContextProvider,
  DataContextProvider,
  IdentityContextProvider,
  BrokerContextProvider
 } from "./config";


const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => (
  <AuthContextProvider>
    <DataContextProvider>
      <IdentityContextProvider>
        <BrokerContextProvider>
          <Provider store={store}>  
            <HashRouter>
              <Component />
            </HashRouter>
          </Provider>
        </BrokerContextProvider>
      </IdentityContextProvider>
    </DataContextProvider>
  </AuthContextProvider>
);

const root = createRoot(rootElement).render(renderApp(Main));

if (module.hot) {
  module.hot.accept("./flows/Main", () => {
    const NextApp = require("./flows/Main").default;
    root.render(renderApp(NextApp));
  });
}
serviceWorker.unregister();