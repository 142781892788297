export function checkTags(account, tags) {
  return tags.every(tag => account.tags.includes(tag)) && account.isActive === '1';
}

export function createPayload(tags, payloads){
    const firstList = tags;
    const secondList = payloads;

    const resultObject = {};

    for (let i = 0; i < firstList.length; i++) {
        const key = firstList[i];
        const value = secondList[i];
      
        if (key && value) {
          if (!resultObject[key]) {
            resultObject[key] = {};
          }
          resultObject[key] = { 'S': value };
        }
      }
    
    return resultObject
}

export function sortByPayloadId(objects) {
  // Deep copy the objects array to avoid modifying the original array
  var sortedObjects = JSON.parse(JSON.stringify(objects));

  for (var i = 0; i < sortedObjects.length - 1; i++) {
      var maxIndex = i;
      for (var j = i + 1; j < sortedObjects.length; j++) {
          // Extracting numeric part of the payload_id for comparison
          var idA = parseInt(sortedObjects[j].payload_id.split('-')[0]);
          var idMin = parseInt(sortedObjects[maxIndex].payload_id.split('-')[0]);
          if (idA > idMin) {
              maxIndex = j;
          }
      }
      // Swap the elements
      if (maxIndex !== i) {
          var temp = sortedObjects[i];
          sortedObjects[i] = sortedObjects[maxIndex];
          sortedObjects[maxIndex] = temp;
      }
  }

  return sortedObjects;
}