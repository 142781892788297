import React from 'react';
import axios from 'axios';

const MAX_RETRIES = 2;

export async function getIP4() {
  for (let retry = 0; retry <= MAX_RETRIES; retry++) {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
      if (retry === MAX_RETRIES) {
        // If it's the last retry, return the error
        return 404;
      }
      // If it's not the last retry, log the error and retry
      console.error(`Error in request, retrying... (Retry ${retry + 1})`, error);
    }
  }
}
