import React from "react";
import axios from "axios";
import { PAYLOAD_SERVER_ENDPOINT } from "../../store/api";

const MAX_RETRIES = 0;


export async function getPayload(jwt, payload_id) {
  return new Promise ((resolve, reject) =>
    {
      for (let retry = 0; retry <= MAX_RETRIES; retry++) {
        try {
          axios.get(PAYLOAD_SERVER_ENDPOINT, {
            params: {
                payload_id: payload_id
            },
            headers: {
              'Authorization': jwt
            }
          })
          .then(response => {
            resolve(response.data)
          })
        } catch (error) {
          if (retry === MAX_RETRIES) {
            // If it's the last retry, return the error
            reject(error.response || { status: 404, data: {} });
          }
          // If it's not the last retry, log the error and retry
          console.error(`Error in request, retrying... (Retry ${retry + 1})`, error);
        }
      }
    }
  ) 
}