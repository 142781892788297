import React, {Component} from "react";

import Loader from "react-loaders";

class LoadingScreenOne extends Component {
    render() {
        return(
            <div id="isLoading" className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-pulse-rise"/>
                    </div>
                    <h6 className="mt-5">
                        Loading...
                    </h6>
                </div>
            </div>
        )}
}   

export default LoadingScreenOne;