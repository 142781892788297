import React, { useState } from "react";
import { createContext, useContext } from "react";


const AuthContext = createContext()
export const AuthContextProvider = (props) => {
    const [auth, setAuth] = useState(null)
    
    return (
        <AuthContext.Provider value = {{
            auth, setAuth
        }}>
            {props.children}
        </AuthContext.Provider>
    );
};


export const useAuth = () => {
    const context = useContext((AuthContext))
    return context
}