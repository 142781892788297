import React, { useState } from "react";
import { createContext, useContext } from "react";


const DataContext = createContext()
export const DataContextProvider = (props) => {
    const [root, setRoot] = useState(null);
    const [accounts, setAccounts] = useState({body: []});
    const [recordAccounts, setRecordAccounts] = useState(null);
    const [contracts, setContracts] = useState(null);
    const [actionLogs, setActionLogs] = useState(null);
    const [pendingActionLogs, setPendingActionLogs] = useState(null);
    const [valueCount, setValueCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [balances, setBalances] = useState(null);
    const [pendingCount, setPendingCount] = useState(0);
    const [pendingWithdraw, setPendingWithdraw] = useState(0);
    const [referral, setReferral] = useState();
    const [balancesRefresh, setBalancesRefresh] = useState(false);
    const [dataRefresh, setDataRefresh] = useState(false);
    
    return (
        <DataContext.Provider value = {{
            root, setRoot,
            accounts, setAccounts,
            recordAccounts, setRecordAccounts,
            contracts, setContracts,
            actionLogs, setActionLogs,
            pendingActionLogs, setPendingActionLogs,
            balances, setBalances,
            balancesRefresh, setBalancesRefresh,
            valueCount, setValueCount,
            pendingCount, setPendingCount,
            pendingWithdraw, setPendingWithdraw,
            referral, setReferral,
            totalCount, setTotalCount,
            dataRefresh, setDataRefresh
        }}>
            {props.children}
        </DataContext.Provider>
    );
};


export const useData = () => {
    const context = useContext((DataContext))
    return context
}

