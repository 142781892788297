import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import { useIdentity, useAuth, useData } from "../../config";

import { ToastContainer, toast, Bounce  } from "react-toastify";

import { getIP4 } from "../../api/ipAddress";
import { getAccounts } from "../../api/CrowdHire/accounts";
import { getPayload } from "../../api/CrowdHire/payloads";
import { sortByPayloadId } from "../../utils/PayloadService";
import LoadingScreenOne from "./LoadingBox";
import { delayFunction } from "../../utils/general_utils";

const UserPages = lazy(() => import("../../flows/UserPages"));
const ProofHistory = lazy(() => import ("../../flows/ProofHistory"))
const NetworkRank = lazy(() => import("../../flows/NetworkRank"))
const Marketplace = lazy(() => import("../../flows/Marketplace"));
const UserSettings = lazy (() => import ("../../flows/UserSettings"))
const Contracts = lazy (() => import("../../flows/Contracts"))
const Redeem = lazy (() => import ("../../flows/Redeem"))

function AppMain () {
    const { auth, setAuth } = useAuth();
    const { setIp, setCountryPayload, setRegionPayload, identityRefresh, setIdentityRefresh } = useIdentity();
    const { accounts, setAccounts, setRecordAccounts, dataRefresh, setDataRefresh, setBalancesRefresh, setRoot, referral, setReferral} = useData();
    const location = useLocation();

      //handle ref
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        const tempRef = urlSearchParams.get('ref');
        if (!referral) {
            if (tempRef) setReferral(tempRef)
        }
    }, [] )

   // useEffect(()=>{
   //     console.log('ref is', referral)
   // },[referral])

     // handle login startup
    useEffect (() => {
        if (auth) {
            setDataRefresh(true)
        }
    },[auth])

    useEffect(() => {
        if (auth) {
            const checkTokenExpiration = () => {
                const expirationTime = auth.idToken.payload.exp
                if (expirationTime) {
                    const currentTime = new Date().getTime();
                    if (currentTime > parseInt(expirationTime, 10)) {
                        console.log('expired!')
                    // Token has expired, handle logout or refresh
                    // For example:
                    // notifyUser();
                    // clearExpiredToken();
                    // redirectToLogin();
                    } else {
                        console.log('not expired', currentTime - parseInt(expirationTime, 10))
                    }
                } else {
                    // No token found, user needs to log in
                    // redirectToLogin();
                }
                };
        
            checkTokenExpiration();
        
            // Optionally, you can set up an interval to periodically check token expiration
            const intervalId = setInterval(checkTokenExpiration, 60000); // Check every minute
            
            return () => clearInterval(intervalId);
        }
      },);

     // handle data context 
    useEffect (() => {
        if (dataRefresh) {
            getAccounts(auth.idToken.jwtToken, "1")
            .then(response => {
                return response
            })
            .catch(response => {
                alert("Error While Trying to Refresh Data, Please try again later")
                console.error("Error during get:", response)
                setAuth(null)
            })
            .then(response => {
                setRoot(response.body[0].account_id)
                setAccounts(response)
                setIdentityRefresh(1)
                return getAccounts(auth.idToken.jwtToken, "2")
            })
            .then(response => {
                setRecordAccounts(response)
                setBalancesRefresh(true)
                setDataRefresh(0)
            })
            .catch(response => {
                alert("Error While Trying to Refresh Data, Please try logging in again later")
                console.error("Error during get:", response)
                })
        }
    },[dataRefresh])

     // TODO: handle identity context, extended loading during identity get may be needed
    useEffect(() => {
        if (identityRefresh) {
            let tempCountryAccount = sortByPayloadId(accounts.body.filter(account => account.tags[0] === "2"))
            if (tempCountryAccount.length !=0) {
                getPayload(auth.idToken.jwtToken, tempCountryAccount[0].payload_id)
                .then(value => {
                  setCountryPayload(value)
                })
            }
            let tempRegionAccount = sortByPayloadId(accounts.body.filter(account => account.tags[0] === "3"))
            if (tempRegionAccount.length !=0) {
                getPayload(auth.idToken.jwtToken, tempRegionAccount[0].payload_id)
                .then(value => {
                  setRegionPayload(value)
                })
            }
            getIP4()
            .then(value=> {
                setIp(value)
                delayFunction(2000)
                .then(()=> setIdentityRefresh(0))
            })
        }
    },[identityRefresh])

    if ( auth ) {
        if ( dataRefresh || identityRefresh) {
            return (
                <>
                    <LoadingScreenOne />
                </>
            );
        }
        else {
            return (
                <Fragment>

                    {/* Components */}
                    <Suspense fallback={
                        <LoadingScreenOne />
                    }>
                        <Route path="/contracts" component={Contracts}/>
                    </Suspense>

                    <Suspense fallback={
                        <LoadingScreenOne />
                    }>
                        <Route path="/network-rank" component={NetworkRank}/>
                    </Suspense>

                    {/* Components */}

                    <Suspense fallback={
                        <LoadingScreenOne />
                    }>
                        <Route path="/proof-history" component={ProofHistory}/>
                    </Suspense>

                    {/* Pages */}

                    <Suspense fallback={
                        <LoadingScreenOne />
                    }>
                        <Route path="/pages" component={UserPages}/>
                    </Suspense>

                    <Suspense fallback={
                        <LoadingScreenOne />
                    }>
                        <Route path="/user" component={UserSettings}/>
                    </Suspense>


                    <Suspense fallback={
                        <LoadingScreenOne />
                    }>
                        <Route path="/pages/logout" component={UserPages}/>
                    </Suspense>

                    <Suspense fallback={
                        <LoadingScreenOne />
                    }>
                        <Route path="/redeem" component={Redeem}/>
                    </Suspense>

                    <Suspense fallback={
                        <LoadingScreenOne />
                    }>
                        <Route path="/marketplace" component={Marketplace}/>
                    </Suspense>

                    <Route exact path="/" render={() => (
                        <Redirect to="/marketplace/manage"/>
                    )}/>
                    <ToastContainer/>
                </Fragment>
            )
        }
    
    } else {
        return (
            <>
            <Suspense fallback={
                <LoadingScreenOne />
            }>
                <Route path="/pages" component={UserPages}/>
            </Suspense>

            <Suspense fallback={
                <LoadingScreenOne />
            }>
                <Route path="/gig-with-crowdhire" component={UserPages}/>
            </Suspense>

            <Route
                render={({ location }) => {
                if (location.pathname === '/pages/terms') {
                    // If the path is /pages/excluded, render the component for that path
                    return <Redirect to="/pages/terms"/>
                } else if (location.pathname === '/pages/register') {
                    return <Redirect to="/pages/register"/>
                } else if (location.pathname === '/gig-with-crowdhire') {
                    return <Redirect to="/gig-with-crowdhire"/>
                }  else{ 
                    // Otherwise, redirect to /pages/login
                    return <Redirect to="/pages/login" />;
                }
                }}
            />
            </>
        )
    }
};

export default AppMain;
