import React, { useState } from "react";
import { createContext, useContext } from "react";


const IdentityContext = createContext()
export const IdentityContextProvider = (props) => {
    const [type, setType] = useState(null)
    const [typeInput, setTypeInput] = useState(null)
    const [ip, setIp] = useState(null);
    const [dob, setDob] = useState(112);
    const [gender, setGender] = useState(null);
    const [region, setRegion] = useState(null);
    const [country, setCountry] = useState(null);
    const [zip, setZip] = useState(null);
    const [email, setEmail] = useState(null);
    const [emailPayload, setEmailPayload] = useState(null)
    const [countryPayload, setCountryPayload] = useState(null)
    const [regionPayload, setRegionPayload] = useState(null)
    const [addressRecord, setAddressRecord] = useState(null)
    const [bankRecord, setBankRecord] = useState(null)
    const [tempRecord, setTempRecord] = useState(null)
    const [identityRefresh, setIdentityRefresh] = useState(true);
    
    return (
        <IdentityContext.Provider value = {{
            type, setType,
            typeInput, setTypeInput,
            ip, setIp,
            dob, setDob,
            gender, setGender,
            region, setRegion,
            country, setCountry,
            zip, setZip,
            email, setEmail,
            emailPayload, setEmailPayload,
            countryPayload, setCountryPayload,
            regionPayload, setRegionPayload,
            addressRecord, setAddressRecord,
            bankRecord, setBankRecord,
            tempRecord, setTempRecord,
            identityRefresh, setIdentityRefresh
        }}>
            {props.children}
        </IdentityContext.Provider>
    );
};


export const useIdentity = () => {
    const context = useContext((IdentityContext))
    return context
}