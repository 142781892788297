import React, { useState } from "react";
import { createContext, useContext } from "react";


const BrokerContext = createContext()
export const BrokerContextProvider = (props) => {
    const [cpxGetTime, setCpxGetTime] = useState(0);
    const [cpxsurveys, setcpxsurveys] = useState(null);
    const [lootablyGetTime, setLootablyGetTime] = useState(0);
    const [lootablysurveys, setlootablysurveys] = useState(null);
    const [brokerRefresh, setBrokerRefresh] = useState(true);
    
    return (
        <BrokerContext.Provider value = {{
            cpxGetTime, setCpxGetTime,
            cpxsurveys, setcpxsurveys,
            lootablyGetTime, setLootablyGetTime,
            lootablysurveys, setlootablysurveys,
            brokerRefresh, setBrokerRefresh
        }}>
            {props.children}
        </BrokerContext.Provider>
    );
};


export const useBroker = () => {
    const context = useContext((BrokerContext))
    return context
}

